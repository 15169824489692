import React, { useState, useEffect } from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SEO from "components/seo";
import { graphql, Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import moment from "moment";
import TranslatorHubHeader from "./TranslatorHubHeader/TranslatorHubHeader";
import HubCategories from "./HubCategories";
import Pagination from "components/TranslatorHub/pagination";
import HubCategoriesMobile from "./HubCategories";
import Footer from "components/layout/footer";

export const TranslationQuery = graphql`
  query translationQuery($limit: Int!, $skip: Int!) {
    allTranslateHub(
      sort: { fields: [data___seo_project_create_date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          data {
            cover_image
            language
            slug
            seo_h1
            category
            category_name
            alt_tag
            seo_project_create_date
            seo_project_small_desc
            seo_project_id
            read_time
          }
        }
      }
    }
  }
`;

const TranslatorHubPage = (props) => {
  const [show, setShow] = useState(false);
  //ab testing
  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();
    useEffect(() => {
      (async () => {
        if (window.dataLayer) {
          await window.dataLayer.push({ event: "optimize.activate" });
        }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    });
    return variant;
  };

  const variant = 1; //useExperiment("H7kig_KJSLakHgOri2vIVQ");
  // const variant_mobile = useExperiment("qTbb91wkRi-Z-X1ZByebNw");

  const TranslationHubData = props.data.allTranslateHub.edges;
  var canonical = "/translator-hub.php";
  if (props.pageContext.currentPage !== 1) {
    canonical = "/translator-hub.php/p/" + props.pageContext.currentPage;
  }

  const [paddingTop, setPaddingTop] = useState(false);

  if (variant) {
    setPaddingTop(true);
  }

  const handleShow = () => {
    window.scrollY ? setShow(true) : setShow(false);
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleShow);
  //   return () => window.removeEventListener("scroll", handleShow);
  // }, []);

  return (
    <React.Fragment>
      {/* {show && (
        <div className="top-0 sticky lg:top-[-1px] bg-[#00338E] text-white z-[999]  text-center text-sm px-4 py-2">
          Make translation as easy as ABC with our MT comparison tool. Read more{" "}
          <Link
            to="/translator-hub/game-changing-translation-mt-engine-comparator"
            rel="nofollow"
            className="review_title text-normal"
          >
            here.
          </Link>
        </div>
      )} */}
      <TranslatorHubHeader
        show={show}
        title={"Translator's Blog"}
        styleOne={variant}
      />
      <SEO
        title="Translation Blog - Tips for Translators | Tomedes"
        description="Tomedes special translators' zone - We share tips for translators, freelancers, languages lovers, interpreters, editors, proofreaders, content writers and localization experts."
        slug={canonical}
      />
      <div className="flex items-center justify-center w-11/12 xl:w-full mx-auto pb-12">
        <div
          className={`relative ${paddingTop && "sm:pt-[8rem]"}  ${
            show ? "pt-[30px] md:pt-[10rem]" : null
          } flex justify-center md:flex-row flex-col  w-full max-w-screen-2xl mt-2`}
        >
          <div className="md:px-4 xl:px-0 lg:mr-20 xl:w-5/12  mt-5">
            <ul>
              {TranslationHubData?.map(({ node }, i) => {
                const { data } = node;
                const {
                  cover_image,
                  alt_tag,
                  category_name,
                  seo_project_id,
                  seo_project_create_date,
                  seo_project_small_desc,
                  slug,
                  read_time,
                  seo_h1,
                } = data;
                return (
                  <li
                    className="max-w-370 sm:max-w-2xl md:w-full border-newBorder border-b-3 mb-7 mx-auto md:mx-0"
                    key={seo_project_id}
                  >
                    <Link to={slug}>
                      <span className="text-2xl py-4 text-newGray hover:text-newLightBlue leading-none break-words">
                        {seo_h1}
                      </span>
                    </Link>
                    <Link to={slug}>
                      <OptimizedImage
                        src={cover_image}
                        alt={alt_tag}
                        lazyload={false}
                        className="w-[343px] sm:w-[590px] md:w-full h-[216px] sm:h-[230px] md:h-auto lg:h-[230px] object-cover mt-5 rounded-[8px]"
                      />
                    </Link>
                    <p className="pb-2 pt-3 text-17 font-normal font-serif">
                      CATEGORY /{" "}
                      <span className="text-lightBlue mt-1 mb-2">
                        {category_name}
                      </span>
                    </p>
                    <i className="pb-5 inline-block font-light font-serif">
                      {moment(seo_project_create_date).format("DD/MM/YYYY")}
                    </i>
                    <p className="pb-5 font-opensans font-normal italic">
                      {seo_project_small_desc}
                    </p>
                    <Link to={slug}>
                      <i className="inline-flex mt-4 items-center pb-5 font-light font-serif text-newGray">
                        Read More{" "}
                        <FontAwesomeIcon icon={faAngleRight} className="ml-6" />
                      </i>
                    </Link>
                    <span className="block pb-5 font-light font-serif text-sm">
                      {read_time}
                    </span>
                  </li>
                );
              })}
            </ul>
            <Pagination
              totalPages={props.pageContext.totalPages}
              currentPage={props.pageContext.currentPage}
              baseUrl="/translator-hub.php"
            />
          </div>
          <div className="md:hidden sticky h-max   top-24">
            <HubCategoriesMobile data={props.pageContext.uniqueCategories} />
          </div>
          <div className="hidden md:block sticky h-max   top-24">
            <HubCategories data={props.pageContext.uniqueCategories} />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default TranslatorHubPage;
